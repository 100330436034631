<template>
    <div>
      <h2 class="mb-2">Sessions</h2>
      <v-data-table
      :headers="sessionHeaders"
      :items="sessions"
      :items-per-page="20"
      item-key="id"
      class="elevation-1"
      sort-desc
      sort-by="createdAt"
      id="sessions"
      >
          <template v-if="sessions.length" v-slot:body="{items}">
              <tbody>
                  <template v-for="(item,i) in items">
                      <tr :class="!currentSession ? '' : currentSession.id === item.id ? 'current-session' : ''" @click="setSession(item)" style="cursor: pointer;">
                        <!-- <td>{{ lastUpdated(item.createdAt) }}</td> -->
                        <!-- <td>{{ lastUpdated(item.resolved) }}</td> -->
                        <!-- <td>{{ item.id }}</td> -->
                        <td>{{ lastUpdated(item.createdAt) }}</td>
                        <td>{{ lastUpdated(getLast(item.readings.items) === "NA" ? "NA" : getLast(item.readings.items)) }}</td>
                        <td>{{ resolved(item.createdAt, getLast(item.readings.items)) }}</td>
                        <td>{{ getHighLow(item.readings.items, "low") }}</td>
                        <td>{{ getHighLow(item.readings.items, "high") }}</td>
                        <td>{{ item.readings.items.length }}</td>
                        <td :style="`background-color: ${checkPassFail(item) === 'Fail' ? '#c40000' : 'green'};color: #fff`">{{ checkPassFail(item) }}</td>
                        <td><v-icon  @click.stop="removeSession(item.id)" color="#c40000">mdi-trash-can</v-icon></td>
                        <!-- <td>{{ item.low }}°F</td>
                        <td>{{ item.high }}°F</td> -->
                        <!-- <td>{{ item.status }}</td> -->
                        <!-- <td>{{ item.alarm.value ? `${item.alarm.type === "low" ? `<` : item.alarm.value : "NA" }}</td> -->
                        <!-- <td>{{ resolved(item.createdAt, item.resolved) || "Not Resolved" }}</td> -->
                        <!-- <td>{{ item.notifications.items.length }}</td> -->
                        <!-- <td>{{ checkNotes(item.notes) }}</td> -->
                        <!-- <td v-if="item.notes"><v-icon @click="updateNotes(item)" color="#009006">mdi-text-box-check-outline</v-icon></td>
                        <td v-else><v-icon @click="updateNotes(item)" color="red">mdi-text-box-edit-outline</v-icon></td> -->
                        <!-- <td v-html="checkMessages(item.messages.items)"></td>
                        <td v-if="item.notes" style="cursor: pointer;" @click="updateNotes(item)">{{ item.notes }}</td>
                        <td v-else><v-icon @click="updateNotes(item)" color="red">mdi-text-box-edit-outline</v-icon></td> -->
                      </tr>
                  </template>
              </tbody>
          </template>
          <template v-else v-slot:no-data><span>No Sessions Found</span></template>
      </v-data-table>    
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    name: "sessions",
    data() {
      return {
        readings: [],
        currentSession: "",
        sessionHeaders: [
        //   { text: "ID", value: "id"},
          { text: "Start", value: "createdAt" },
          { text: "End", value: "" },
          { text: "Total Time", value: "" },
          { text: "Low (°F)", value: "" },
          { text: "High (°F)", value: "" },
          { text: "Readings", value: "" },
          { text: "Pass/Fail", value: "" },
          { text: "Actions", value: "" },
          // { text: "End", value: "resolved" },
        //   { text: "Type", value: "alarm.type" },
        //   // { text: "Status", value: "status" },
        //   // { text: "Trigger", value: "alarm.value" },
        //   { text: "Result", value: "result" },
        //   { text: "Resolution Time", value: "" },
        //   // { text: "Notifications", value: "" },
        //   { text: "User Response", value: "" },
        //   { text: "Notes", value: "notes" },
        ]
      };
    },
    props: ["sessions", "currentItem"],
    async created() {
        // this.readings = this.sessions
    },
    watch: {
        sessions(val) {
            this.readings = val.map(item => item.readings.items).sort((a,b) => new Date(a.createdAt) - new Date(b.createdAt))
        },
        currentItem(item) {
            this.currentSession = item
        }
    },
    computed: {
    //   ...mapState("sensor", ["sensor", "sensorProducts"]),
    //   eventsByDate() {
    //     if (!this.sensor.length) return []
    //     else return this.sensorEvents.filter(item => item.createdAt >= this.sensor[0].createdAt)
    //   }
        endTime() {
            return this.readings
            let res = this.sessions.map(item => item.readings.items).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
            return res[res.length - 1]
            // let res = val.sort((a,b) => new Date(a.createdAt) - new Date(b.createdAt))
            // console.log(res, "res")
            // return
            // return res[res.length - 1].createdAt
            // else return this.sessions.map(item => item.readings.items).sort((a,b) => new Date(a.createdAt) - new Date(b.createdAt))[0]
        },
    },
    methods: {
        getLast(items) {
            if (!items.length) return "NA"
            let last = JSON.parse(JSON.stringify(items)).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
            return last.createdAt
        },
        getHighLow(items, type) {
            if (!items.length) return "NA"
            if (type === "high") {
                return Math.max(...items.map(item => item.min))
            }
            else {
                return Math.min(...items.map(item => item.min))
            }
        },
        lastUpdated(date) {
            if (!date) return "In Progress";
            else if (date === "NA") return "NA";
            let fullDate = new Date(date).toLocaleDateString();
            let todayDate = new Date().toLocaleDateString();
            if (fullDate === todayDate) {
                fullDate = "Today";
            }
            return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
        },
        setSession(item) {
            this.currentSession = item
            this.$emit("setSession", item)
        },
        async removeSession(id) {
            await this.$store.dispatch("sensor/removeSession", id)
            let index = this.sessions.findIndex(item => item.id === id)
            this.sessions.splice(index, 1)
        },
        checkPassFail(item) {
            let type = "range"
            if (item.min) type = "min"
            let readings = item.readings.items
            let temps = readings.map(item => item.min)
            let hiTemp = Math.max(...temps)
            let loTemp = Math.min(...temps)
            if (type === "range") {
                return hiTemp > item.high || loTemp < item.low ? 'Fail' : 'Pass'
            }
            else {
                return loTemp >= item.min ? 'Pass' : 'Fail'
            }
        },
        resolved(initial, final) {
            if (final === "NA") return "NA";
            let time = ((new Date(final) - new Date(initial)) / 60000)
            if (time > 1440) return `${(time / 1440).toFixed(0)} Day/s`
            else if (time > 60) return `${(time / 60).toFixed(0)} Hour/s`
            else if (time < 1) return `${(time * 60).toFixed(0)} Sec/s`
            else return `${time.toFixed(0)} Min/s`
        },
    //   checkMessages(messages) {
    //     let msgs = messages.filter(msg => msg.userResponse)
    //     if (msgs.length) return  msgs.map(msg => `${msg.responseBy}: ${msg.userResponse} (${this.lastUpdated(msg.updatedAt)})`).join('<br>')
    //     else return "NA "
    //   },
    //   lastUpdated(date) {
    //     let fullDate = new Date(date).toLocaleDateString();
    //     let todayDate = new Date().toLocaleDateString();
    //     if (fullDate === todayDate) {
    //       return `${new Date(date).toLocaleTimeString()}`
    //     }
    //     else return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    //   },
    //   updateNotes(item) {
    //     this.$swal({
    //       input: "textarea",
    //       // inputLabel: "Event Notes",
    //       inputPlaceholder: "Enter notes here...",
    //       inputAttributes: {
    //         "aria-label": "Enter notes here"
    //       },
    //       inputValue: item.notes ? item.notes : "",
    //       title: 'Event Notes',
    //       // icon: 'info',
    //       showCancelButton: true,
    //       confirmButtonColor: '#4baf4f',
    //       cancelButtonColor: '#d33',
    //       showLoaderOnConfirm: true,
    //       confirmButtonText: 'Update',
    //       'cancelButtonText': 'Cancel',
    //       'iconColor': this.siteColor,
    //       preConfirm: async (notes) => {
    //         item.notes = notes
    //         await this.$store.dispatch("sensor/updateEventNotes", item)
    //       },
    //       allowOutsideClick: () => !this.$swal.isLoading()
    //     }).then(async (result) => {
    //       if (result.isConfirmed) {
    //         this.$swal('Event notes saved!', '', 'success')
    //       }
    //     });
    //   },
    //   lastUpdated(date) {
    //     if (!date) return "In Progress";
    //     let fullDate = new Date(date).toLocaleDateString();
    //     let todayDate = new Date().toLocaleDateString();
    //     if (fullDate === todayDate) {
    //       fullDate = "Today";
    //     }
    //     return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    //   },
    //   capitalize(word) {
    //     return word.slice(0, 1).toUpperCase() + word.slice(1);
    //   },
    //   resolved(initial, final) {
    //     if (!final) return "Not Resolved";
    //     let time = ((new Date(final) - new Date(initial)) / 60000)
    //     if (time > 1440) return `${(time / 1440).toFixed(0)} Day/s`
    //     else if (time > 60) return `${(time / 60).toFixed(0)} Hour/s`
    //     else if (time < 1) return `${(time * 60).toFixed(0)} Sec/s`
    //     else return `${time.toFixed(0)} Min/s`
    //   }
    },
  };
  </script>
  
  <style lang="scss">
    .current-session {
      background-color: #292c2d;
      color: #fff;
      
      &:hover {
        background-color: #292c2d !important;
      }
    }
  </style>